import { ModelSelect } from 'vue-search-select';
import DatePicker from 'vue2-datepicker';
import commonHelper from '@/app/utility/common.helper.utility';
export default {
  name: 'TaxRegimeDetails',
  components: {
    ModelSelect,
    DatePicker
  },
  watch: {
    updateMode: function() {
      this.getOrganizationType();
    }
  },
  data() {
    return {
      showRegimeDetails: false,
      loader: false,
      editMode: false,
      updateMode: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      form: {
        regimeId: 0,
        taxTypeGroup: null,
        taxValue: null,
        orgTypeName: null,
        orgTypeCode: null,
        start_date_end_date: null,
        startDate: null,
        endDate: null
      },
      selectedOrgType: {
        value: null,
        text: null
      },
      orgTypeData: [
        {
          value: null,
          text: null
        }
      ]
    };
  },
  mounted() {
    this.eventBus.$off('createFormActions');
    this.eventBus.$off('taxRegimeDetails');
    this.eventBus.$on('createFormActions', actionName => {
      if (actionName === 'add') {
        this.resetform();
        this.showAlert = false;
        this.editMode = true;
        this.updateMode = true;
        this.showRegimeDetails = true;
      }
      if (actionName === 'edit' || actionName === 'update') {
        this.editMode = false;
        this.updateMode = true;
      }
      if (actionName === 'save' && this.updateMode) {
        this.addEditTaxRegime();
      }
      if (actionName === 'upload') {
        this.eventBus.$emit('commonUpload', { id: this.form.regimeId });
      }
    });
    this.eventBus.$on('taxRegimeDetails', Obj => {
      this.form.regimeId = Obj.tax_regime_id;
      this.form.taxTypeGroup = Obj.tax_type_group_value;
      this.form.taxValue = Obj.tax_type_value;
      this.form.orgTypeName = Obj.org_type;
      this.form.orgTypeCode = Obj.org_type_lookup;
      this.selectedOrgType = {
        value: Obj.org_type_lookup,
        text: Obj.org_type
      };          
      this.form.start_date_end_date = [new Date(Obj.start_date), new Date(Obj.end_date)];
    });
  },
  methods: {
    addEditTaxRegime() {
      const payload = {
        org_type_lookup: this.selectedOrgType.value,
        tax_regime_id: this.form.regimeId,
        tax_type_group_value: this.form.taxTypeGroup,
        tax_type_value: this.form.taxValue,
        start_date: commonHelper.formattedDate(this.form.start_date_end_date[0]),
        end_date: commonHelper.formattedDate(this.form.start_date_end_date[1]),
  
      };
      this.$store
        .dispatch('defineTaxRegime/addEditTaxRegime', payload)
        .then(response => {
          this.$emit('updateTaxRegimeList');
          this.editMode = false;
          this.updateMode = false;
          this.showAlert = true;
          this.loader = false;
          if (response.status === 201) {
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        });
    },
    getOrganizationType() {
      this.$store
        .dispatch('shared/getDataFromLookupsByLookupType', 'SECGRP')
        .then(response => {
          if (response.status === 200) {
            const results = response.data.data;
            const orgType = results.map(type => {
              return {
                value: type.lookup_code.toUpperCase(),
                text: type.meaning
              };
            });
            this.orgTypeData = orgType;
          }
        });
    },
    resetform() {
      this.form.regimeId = null;
      this.form.taxTypeGroup = null;
      this.form.taxValue = null;
      this.form.orgTypeName = null;
      this.form.orgTypeCode = null;
      this.selectedOrgType = {
        value: null,
        text: null
      };
      this.form.startDate = null;
      this.form.endDate = null;
    }
  }
};
