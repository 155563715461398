import commonHelper from '@/app/utility/common.helper.utility';
import DatePicker from 'vue2-datepicker';
import { ModelSelect } from 'vue-search-select';
import TaxRegimeDetails from './taxRegimeDetails';
export default {
  name: 'TaxRegime',
  components: {
    TaxRegimeDetails,
    ModelSelect,
    DatePicker
  },
  watch: {
    currentPage: function() {
      this.getTaxRegimeList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getTaxRegimeList();
    }
  },
  data() {
    return {
      unsubscribe: null,
      showRegimeDetails: false,
      loader: false,
      editMode: false,
      payload: {},
      updateMode: false,
      pageOptions: commonHelper.getPageOption(),
      perPage: commonHelper.perPageRecord,
      currentPage: 1,
      totalRows: null,
      taxTypeGrp: null,
      taxValue: null,
      selectedOrgType: {
        value: null,
        text: null
      },
      orgDataList: [
        {
          value: null,
          text: null
        }
      ],
      startDate: null,
      start_date_end_date: [],
      endDate: null,
      data: [],
      fields: [
        {
          key: 'tax_regime_id',
          class: 'd-none'
        },
        {
          key: 'org_type_lookup',
          class: 'd-none'
        },
        {
          key: 'tax_type_group_value',
          label: 'Tax Type Group'
        },
        {
          key: 'tax_type_value',
          label: 'Tax Value'
        },
        {
          key: 'org_type',
          label: 'ORG Type'
        },
        {
          key: 'start_date'
        },
        {
          key: 'end_date'
        }
      ]
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.showRegimeDetails = true;
        }
        if (this.showRegimeDetails) {
          setTimeout(() => {
            this.eventBus.$emit('createFormActions', actionName);
          }, 0);
        }
        if (actionName === 'download' && !this.showRegimeDetails) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'defineTaxRegime/getTaxRegimeList',
            'Tax-Regime',
            () => (this.loader = false)
          );
        }
      }
    });
    this.getOrganizationType();
    this.getTaxRegimeList();
  },
  methods: {
    getOrganizationType() {
      this.$store
        .dispatch('shared/getDataFromLookupsByLookupType', 'SECGRP')
        .then(response => {
          if (response.status === 200) {
            const results = response.data.data;
            const orgType = results.map(type => {
              return {
                value: type.lookup_code.toUpperCase(),
                text: type.meaning
              };
            });
            this.orgDataList = orgType;
          }
        });
    },
    getTaxRegimeList() {
      this.payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        tax_type_group_value: this.taxTypeGrp,
        tax_type_value: this.taxValue,
        org_type_lookup: this.selectedOrgType.value,
        start_date: commonHelper.formattedDate(this.start_date_end_date[0]),
        end_date: commonHelper.formattedDate(this.start_date_end_date[1])
        // start_date: this.startDate
        //   ? moment(this.startDate).format(appStrings.DATEFORMAT)
        //   : null,
        // end_date: this.endDate
        //   ? moment(this.endDate).format(appStrings.DATEFORMAT)
        //   : null
      };
      this.loader = true;
      this.$store
        .dispatch('defineTaxRegime/getTaxRegimeList', this.payload)
        .then(response => {
          this.loader = false;
          this.data = response.data.data.page;
          this.totalRows = response.data.data.total_elements;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    updateTaxRegimeList() {
      this.getTaxRegimeList();
    },
    rowSelected(rowData) {
      if (this.$router.currentRoute.name === 'defineTaxRegime') {
        this.showRegimeDetails = true;
        setTimeout(() => {
          this.eventBus.$emit('taxRegimeDetails', rowData);
        }, 0);
      } else {
        this.$emit('selectedTaxRegime', rowData);
      }
    },
    clearSearch() {
      this.taxTypeGrp = null;
      this.taxValue = null;
      this.selectedOrgType = {
        value: null,
        text: null
      };
      this.startDate = null;
      this.endDate = null;
      this.start_date_end_date = [];
      this.getTaxRegimeList();
    },
    resetModal() {
      this.editMode = false;
      this.updateMode = false;
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
